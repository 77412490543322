var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-info"},[_vm._m(0),_c('div',{staticClass:"content px-30"},[_c('a-form',{staticClass:"cus-form",attrs:{"form":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-row',{attrs:{"gutter":50}},[_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_vm._v(_vm._s(_vm.proveInfo.realName))])],1),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"民族"}},[_vm._v(_vm._s(_vm.proveInfo.nationName))])],1)],1),_c('a-row',{attrs:{"gutter":50}},[_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"性别"}},[_vm._v(_vm._s(_vm.proveInfo.sex == '1' ? '男' : '女'))])],1),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"出生日期"}},[_vm._v(_vm._s(_vm.proveInfo.birthTime))])],1)],1),_c('a-row',{attrs:{"gutter":50}},[_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"证件类型"}},[_vm._v(_vm._s(_vm._f("getIdType")(_vm.proveInfo.idType)))])],1),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"证件号码"}},[_vm._v(_vm._s(_vm.proveInfo.idNo))])],1)],1),_c('a-row',{attrs:{"gutter":50}},[_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"婚姻状况"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'marriageState',
                {
                  initialValue: _vm.proveInfo.marriageState,
                  rules: [
                    {
                      required: true,
                      message: '必填'
                    }
                  ]
                }
              ]),expression:"[\n                'marriageState',\n                {\n                  initialValue: proveInfo.marriageState,\n                  rules: [\n                    {\n                      required: true,\n                      message: '必填'\n                    }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请选择婚姻状况"}},_vm._l((_vm.marriageType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.desc))])}),1)],1)],1),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"政治面貌"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'politicsFace',
                {
                  initialValue: _vm.proveInfo.politicsFace,
                  rules: [
                    {
                      required: true,
                      message: '必填'
                    }
                  ]
                }
              ]),expression:"[\n                'politicsFace',\n                {\n                  initialValue: proveInfo.politicsFace,\n                  rules: [\n                    {\n                      required: true,\n                      message: '必填'\n                    }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请选择政治面貌"}},_vm._l((_vm.identityType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.desc))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":50}},[_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"籍贯"}},[_vm._v(_vm._s(_vm.proveInfo.nativePlace))])],1),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"户口性质"}},[_vm._v(_vm._s(_vm._f("getRegistryNature")(_vm.proveInfo.registryNature))+" ")])],1)],1),_c('a-row',{attrs:{"gutter":50}},[_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"户籍所在地"}},[_vm._v(_vm._s(_vm.proveInfo.registryAddress)+" ")])],1),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"职业分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'jobState',
                {
                  initialValue: _vm.proveInfo.jobState,
                  rules: [
                    {
                      required: true,
                      message: '必填'
                    }
                  ]
                }
              ]),expression:"[\n                'jobState',\n                {\n                  initialValue: proveInfo.jobState,\n                  rules: [\n                    {\n                      required: true,\n                      message: '必填'\n                    }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请选择在职状况"}},_vm._l((_vm.professionType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.desc))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":50}},[_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phoneNumber',
                {
                  initialValue: _vm.proveInfo.phoneNumber,
                  rules: [
                    {
                      required: true,
                      message: '必填'
                    },
                    { validator: _vm.validatePhone }
                  ]
                }
              ]),expression:"[\n                'phoneNumber',\n                {\n                  initialValue: proveInfo.phoneNumber,\n                  rules: [\n                    {\n                      required: true,\n                      message: '必填'\n                    },\n                    { validator: validatePhone }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"固定号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'telPhone',
                {
                  initialValue: _vm.proveInfo.telPhone,
                  rules: []
                }
              ]),expression:"[\n                'telPhone',\n                {\n                  initialValue: proveInfo.telPhone,\n                  rules: []\n                }\n              ]"}],attrs:{"placeholder":"请输入"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":50}},[_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"E-mail"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  initialValue: _vm.proveInfo.email,
                  rules: [
                    {
                      required: true,
                      message: '必填'
                    },
                    { validator: _vm.validateEmail }
                  ]
                }
              ]),expression:"[\n                'email',\n                {\n                  initialValue: proveInfo.email,\n                  rules: [\n                    {\n                      required: true,\n                      message: '必填'\n                    },\n                    { validator: validateEmail }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"邮编"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'zipCode',
                {
                  initialValue: _vm.proveInfo.zipCode,
                  rules: []
                }
              ]),expression:"[\n                'zipCode',\n                {\n                  initialValue: proveInfo.zipCode,\n                  rules: []\n                }\n              ]"}],attrs:{"placeholder":"请输入"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":50}},[_c('a-col',{attrs:{"md":{ span: 24 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"通讯地址","label-col":{ span: 3 },"wrapper-col":{ span: 14 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'postalAddress',
                {
                  initialValue: _vm.proveInfo.postalAddress,
                  rules: [
                    {
                      required: true,
                      message: '必填'
                    }
                  ]
                }
              ]),expression:"[\n                'postalAddress',\n                {\n                  initialValue: proveInfo.postalAddress,\n                  rules: [\n                    {\n                      required: true,\n                      message: '必填'\n                    }\n                  ]\n                }\n              ]"}],attrs:{"placeholder":"请输入"}})],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('a-form-item',[_c('a-button',{staticClass:"cus-button",attrs:{"type":"primary","disabled":_vm.proveInfo.data === '1' || _vm.proveInfo.data === '2',"html-type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v("保存修改")])],1)],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-line-wrap d-flex align-center py-10"},[_c('p',{staticClass:"fs-16 fw-bold"},[_vm._v("基本信息")]),_c('span',{staticClass:"line"})])
}]

export { render, staticRenderFns }