<template>
  <div class="congratulation-book-second">
    <div class="title">以下有关信息将报教育部进行学籍注册，请您仔细核对</div>
    <div class="student-info">
      <a-row>
        <a-col :span="12">
          <div class="info-row">
            <div class="label">姓名：</div>
            <div class="value">{{ content.realName || '暂无' }}</div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="info-row">
            <div class="label">性别：</div>
            <div class="value">{{ content.sex === '1' ? '男' : '女' || '暂无' }}</div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="info-row">
            <div class="label">名族：</div>
            <div class="value">{{ content.nationName || '暂无' }}</div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="info-row">
            <div class="label">身份证号：</div>
            <div class="value">{{ content.idNo || '暂无' }}</div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="info-row">
            <div class="label">报读专业：</div>
            <div class="value">{{ content.majorName || '暂无' }}</div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="info-row">
            <div class="label">报读时间：</div>
            <div class="value">{{ content.forTime || '暂无' }}</div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="pay-attention">
      <div class="main">注意事项</div>
      <div class="paragraph" v-html="content.mattersNeedingAtt"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookSecond',
  components: {},
  props: {
    content: { type: Object, default: () => {} }
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.congratulation-book-second {
  color: #000;
  padding-top: 30px;
  margin: 0 40px;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .student-info {
    .info-row {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 10px;
      .label {
        font-size: 16px;
        width: 90px;
        text-align: right;
      }
      .value {
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex: 1;
        height: 100%;
        border-bottom: 1px solid #000;
      }
    }
  }
}
.pay-attention {
  border-radius: 5px;
  border: 1px dashed #a19e97;
  background: #fdf9d6;
  padding: 5px;
  min-height: 344px;
  margin: 20px 0;
  position: absolute;
  bottom: 55px;
  left: 40px;
  right: 40px;

  .main {
    font-weight: bold;
    font-size: 20px;
  }
  .paragraph {
    font-size: 15px;
  }
}
</style>
