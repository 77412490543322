<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 20:26:40
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:20:20
 * @FilePath: \crm-education\src\views\student\my_school_role\c\HighestEducationInfo.vue
-->
<template>
  <div class="highest-education-info">
    <div class="title-line-wrap d-flex align-center py-10">
      <p class="fs-16 fw-bold">最高学历</p>
      <span class="line"></span>
    </div>
    <div class="content px-30">
      <a-form class="cus-form">
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="原学历层次">{{
              proveInfo.oldEducationalLevel | getOldEducationalLevel
            }}</a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="原毕业学校">{{
              proveInfo.oldSchool
            }}</a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="毕业年份">{{
              proveInfo.oldGraduateTime |convertOldGraduateTime
            }}</a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hightest_education_info',
  props: {
    proveInfo: {
      type: Object
    }
  },
  filters: {
    getOldEducationalLevel(val) {
      switch (String(val)) {
        case '1':
          return '高中'
        case '2':
          return '高中同等学力'
        case '3':
          return '专科'
        case '4':
          return '本科'
          case '5':
              return '硕士研究生'
          case '6':
              return '博士研究生'
          case '7':
              return '高职本科'
          case '8':
              return '高职专科'
          case '9':
              return '技工学校（技师学院）'
          case '10':
              return '中专'
          case '11':
              return '职高'
        default:
          return ''
      }
    },
    convertOldGraduateTime(oldGraduateTime){
      let date = new Date(oldGraduateTime);
      return date.getFullYear();
    }
  }
}
</script>
