<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 20:52:53
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 20:56:53
 * @FilePath: \crm-education\src\views\student\my_school_role\c\EducationPhotoInfo.vue
-->
<template>
  <div class="education-photo-info">
    <div class="title-line-wrap d-flex align-center py-10">
      <p class="fs-16 fw-bold">学历证明材料</p>
      <span class="line"></span>
    </div>
    <div class="content px-30">
      <div class="d-flex justify-center align-center flex-wrap">
        <div class="photo-item text-center">
          <div class="photo-wrap">
            <img :src="setPhoto" alt="学历证明材料" />
          </div>
          <p>高中或职中、中专、技校等同等学历的毕业证原件</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'education_photo_info',
  props: {
    proveInfo: {
      type: Object
    }
  },
  computed: {
    setPhoto() {
      let imgUrl = ''
      const { majorLevel, juniorCollegeImg, highSchoolImg } = this.proveInfo
      if (majorLevel === '1') {
        imgUrl = highSchoolImg
      } else if (majorLevel === '2') {
        imgUrl = juniorCollegeImg
      }
      return imgUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.education-photo-info {
  .photo-item {
    .photo-wrap {
      width: 400px;
      height: 240px;
      border: 1px solid #d7dcdc;
    }
  }
}
</style>
