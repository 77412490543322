var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{staticClass:"cus-modal education-change-dialog",attrs:{"width":"420px","footer":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"title"},[_c('p',{staticClass:"text-center"},[_vm._v("申请学籍异动")])]),_c('div',{staticClass:"info"},[_c('a-form',{staticClass:"cus-form",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"申请学籍异动类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'field1',
            {
              rules: [
                {
                  required: true,
                  message: '必填'
                }
              ]
            }
          ]),expression:"[\n            'field1',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '必填'\n                }\n              ]\n            }\n          ]"}],attrs:{"placeholder":"请选择异动类型"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 未婚 ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" 已婚 ")])],1)],1),_c('a-form-item',{attrs:{"label":"申请原因"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'field1',
            {
              rules: [
                {
                  required: true,
                  message: '必填'
                }
              ]
            }
          ]),expression:"[\n            'field1',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '必填'\n                }\n              ]\n            }\n          ]"}],attrs:{"placeholder":"填写申请原因","auto-size":{ minRows: 5, maxRows: 10 }}})],1),_c('a-form-item',{attrs:{"label":"申请日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'field1',
            {
              rules: [
                {
                  required: true,
                  message: '必填'
                }
              ]
            }
          ]),expression:"[\n            'field1',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '必填'\n                }\n              ]\n            }\n          ]"}],staticClass:"cus-date-picker",attrs:{"show-time":"","format":"YYYY-MM-DD","placeholder":"请选择申请日期"}})],1),_c('div',{staticClass:"text-center"},[_c('a-form-item',[_c('a-button',{staticClass:"cus-button",attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v("确认申请")])],1),_c('span',{staticClass:"required pl-5 color-red"},[_vm._v("学籍异动")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }