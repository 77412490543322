export const livingType = [
  { desc: '课程直播记录', code: '1' },
  { desc: '活动直播记录', code: '2' },
  { desc: '我的观看记录', code: '3' }
]

// 婚姻状况
export const marriageType = [
  { desc: '未婚', code: '1' },
  { desc: '已婚', code: '2' },
  { desc: '离婚', code: '3' },
  { desc: '丧偶', code: '4' }
]

// 择政治面貌
export const identityType = [
  { desc: '中国共产党党员', code: '1' },
  { desc: '群众', code: '2' },
  { desc: '中国共产主义青年团团员', code: '3' },
  { desc: '无党派人士', code: '4' },
  { desc: '中国共产党预备党员', code: '5' },
  { desc: '中国国民党革命委员会会员', code: '6' },
  { desc: '中国民主同盟盟员', code: '7' },
  { desc: '中国民主建国会会员', code: '8' },
  { desc: '中国民主促进会会员', code: '9' },
  { desc: '中国农工民主党党员', code: '10' },
  { desc: '中国致公党党员', code: '11' },
  { desc: '九三学社社员', code: '12' },
  { desc: '台湾民主自治同盟盟员', code: '13' }
]

// 户籍所在地
export const residenceType = [
  { desc: '城镇户口', code: '1' },
  { desc: '农村户口', code: '2' },
  { desc: '其他', code: '3' }
]

// 职业分类
export const professionType = [
  { desc: '国家机关、党群组织、企业、事业单位负责人', code: '1' },
  { desc: '专业技术人员', code: '2' },
  { desc: '办事人员和有关人员', code: '3' },
  { desc: '生产制造及有关人员', code: '4' },
  { desc: '社会生产服务和生活服务人员', code: '5' },
  { desc: '农、林、牧、渔业生产及辅助人员', code: '6' },
  { desc: '军人', code: '7' },
  { desc: '不便分类的其他从业人员', code: '8' }
]
