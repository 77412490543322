<template>
  <div class="congratulation-book">
    <div class="content" v-html="content.contentZm"></div>
    <div class="end">
      <div class="end-circle" :style="img"></div>
      <div class="end-txt">{{ content.unitName }}</div>
      <div class="end-time">{{ content.admissionNoticeTime }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookFirst',
  components: {},
  props: {
    content: { type: Object, default: () => {} }
  },
  data() {
    return {}
  },
  computed: {
    img() {
      return {
        background: `url(${this.content.sealImg}) center center / 100% auto no-repeat`
      }
    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss">
.congratulation-book {
  color: #000;
  padding-top: 30px;
  margin: 0 40px;
  .content {
    min-height: 100px;
    font-size: 16px;
    p {
      text-indent: 20px;
      padding-bottom: 10px;
      &:first-child {
        font-size: 20px;
      }
      &:last-child {
      }
    }
    .tit {
      font-weight: bold;
      font-size: 20px;
    }
  }
  .end {
    font-size: 20px;
    text-align: right;
    position: relative;
    .end-circle {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }
  }
}
</style>
