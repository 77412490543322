<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 12:23:21
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:19:15
 * @FilePath: \crm-education\src\views\student\my_school_role\index.vue
-->
<template>
  <div class="student-my-school-role">
    <div class="mb-10">
      <a-card :bordered="false" class="cus-card no-border">
        <a-tabs v-model="activeKey" class="cus-tabs">
          <a-tab-pane key="1">
            <template slot="tab">
              <p class="fs-18">我的学籍</p>
            </template>
            <a-row type="flex" justify="space-between" align="middle">
              <a-col :md="{ span: 10 }" :xs="{ span: 24 }">
                <div class="d-flex align-center">
                  <a-avatar shape="square" :size="88" icon="user" :src="proveInfo.studIdImg" />
                  <div class="pl-10">
                    <p class="fs-16 mb-5 fw-bold">{{ proveInfo.realName || '暂无数据' }}</p>
                    <div class="fs-12">学号：{{ proveInfo.studNo || '暂无数据' }}</div>
                    <div class="fs-12">手机号：{{ proveInfo.phoneNumber || '暂无数据' }}</div>
                    <div class="fs-12">证件号：{{ proveInfo.idNo || '暂无数据' }}</div>
                  </div>
                </div>
              </a-col>
              <a-col :md="{ span: 14 }" :xs="{ span: 24 }">
                <div class="role-tags d-flex justify-between align-center flex-wrap py-15 text-center">
                  <div class="tag-wrap linear-gradient-blue">
                    <div class="tag">
                      <p class="fs-14">
                        {{ proveInfo.completeForInfo ? '已完善' : '未完善' }}
                      </p>
                      <p class="fs-12">报名信息</p>
                    </div>
                  </div>
                  <div class="tag-wrap linear-gradient-light-orange">
                    <div class="tag">
                      <p class="fs-14">
                        {{ proveInfo.completeForImg ? '已完善' : '未完善' }}
                      </p>
                      <p class="fs-12">证件信息</p>
                    </div>
                  </div>
                  <div class="tag-wrap linear-gradient-purple">
                    <div class="tag">
                      <p class="fs-14">
                        {{ proveInfo.completeForSign ? '已签名' : '未签名' }}
                      </p>
                      <p class="fs-12">电子签名</p>
                    </div>
                  </div>
                  <div class="tag-wrap linear-gradient-dark-orange">
                    <div class="tag">
                      <p class="fs-14">
                        {{ proveInfo.dataState | getDataState }}
                      </p>
                      <p class="fs-12">资料状态</p>
                    </div>
                  </div>
                  <div class="tag-wrap linear-gradient-light-blue">
                    <div class="tag">
                      <p class="fs-14">
                        {{ proveInfo.proveState | getProveState }}
                      </p>
                      <p class="fs-12">学籍状态</p>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane key="2">
            <template slot="tab">
              <p class="fs-18" v-if="isShow()">入学通知书</p>
            </template>
            <!-- <div>
              <div class="notice-img-wrap">
                <img src="" alt="入学通知书" />
              </div>
              <div class="text-center">
                <a-button type="primary" class="mx-10">下载</a-button>
                <a-button type="primary" class="cus-button mx-10">打印</a-button>
              </div>
            </div> -->
            <div id="print333" v-if="bookInfo.dataState === '4'">
              <notice-book id="aaa" class="mb-20" :title="bookInfo.unitName">
                <book-first :content="bookInfo"></book-first>
              </notice-book>
              <notice-book id="bbb" class="book-back" page="2" :title="bookInfo.unitName">
                <book-second :content="bookInfo"></book-second>
              </notice-book>
            </div>
            <a-empty v-else>
              <span slot="description">暂无录取通知书</span>
            </a-empty>
          </a-tab-pane>
          <div v-if="activeKey === '2' && bookInfo.dataState === '4'" slot="tabBarExtraContent">
            <!--<a-button style="margin-right:10px;" type="primary" size="small" :loading="printing" @click="print"
              >打印</a-button
            >-->
            <a-button type="primary" size="small" :loading="downloading" @click="downloadBook">下载</a-button>
          </div>
        </a-tabs>
      </a-card>
    </div>
    <div class="mb-10" v-if="activeKey === '1'">
      <a-card :bordered="false" class="cus-card no-border">
        <template slot="title">
          <div class="section-header dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">资料信息</h2>
            <!-- 后期对接开发 -->
            <!-- <p class="fs-14 color-gray link color-blue" @click="onEducationChange">
              申请学籍异动
            </p> -->
          </div>
        </template>
        <div class="py-10">
          <base-info :proveInfo="proveInfo" @on-submit="onBaseInfoSubmit"></base-info>
        </div>
        <div class="py-10">
          <subject-info :proveInfo="proveInfo"></subject-info>
        </div>
        <div class="py-10">
          <highest-education-info :proveInfo="proveInfo"></highest-education-info>
        </div>
        <div class="py-10" v-if="!(proveInfo.organizationId ==100000 || proveInfo.organizationId ==400000) ">
          <sign-online-info :proveInfo="proveInfo"></sign-online-info>
        </div>
        <div class="py-10">
          <face-photo-info :proveInfo="proveInfo"></face-photo-info>
        </div>
        <div class="py-10">
          <id-photo-info :proveInfo="proveInfo"></id-photo-info>
        </div>
        <div class="py-10" v-if="!(proveInfo.organizationId ==100000 || proveInfo.organizationId ==400000) ">
          <education-photo-info :proveInfo="proveInfo"></education-photo-info>
        </div>
        <education-change-dialog ref="educationChangeDialog"></education-change-dialog>
      </a-card>
    </div>
  </div>
</template>

<script>
// import helper from '@/utils/helper'
import BaseInfo from './c/BaseInfo'
import SubjectInfo from './c/SubjectInfo'
import HighestEducationInfo from './c/HighestEducationInfo'
import SignOnlineInfo from './c/SignOnlineInfo'
import FacePhotoInfo from './c/FacePhotoInfo'
import IdPhotoInfo from './c/IdPhotoInfo'
import EducationPhotoInfo from './c/EducationPhotoInfo'
import EducationChangeDialog from './c/EducationChangeDialog'
import NoticeBook from '@/components/NoticeBook'
import BookFirst from './c/BookFirst'
import BookSecond from './c/BookSecond'
import api from '@/api'
import { message } from 'ant-design-vue'
import print from 'print-js'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
export default {
  name: 'student_my_school_role',
  components: {
    BaseInfo,
    SubjectInfo,
    HighestEducationInfo,
    SignOnlineInfo,
    FacePhotoInfo,
    IdPhotoInfo,
    EducationPhotoInfo,
    EducationChangeDialog,
    NoticeBook,
    BookFirst,
    BookSecond
  },
  data() {
    return {
      activeKey: '1',
      printing: false,
      proveInfo: {},
      bookInfo: {},
      downloading: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.initStudProve()
      this.getBook()
    },
    async initStudProve() {
      // TODO: forId 是什么
      // const { userId } = helper.getUserInfo()
      let res = await api.student.getStudProve()
      if (res.code === 200) {
        /*res.data.marriageState = '1'*/
        this.proveInfo = res.data
      }
    },
    async onBaseInfoSubmit(data) {
      let res = await api.student.updateStudProve(data)
      if (res.code === 200) {
        message.success('提交成功')
        await this.init()
      } else {
        message.error(res.msg)
      }
    },
    onEducationChange() {
      this.$refs.educationChangeDialog.open()
    },
    async getBook() {
      const { data = {} } = await api.student.getNoticeBook()
      this.bookInfo = data
    },
    async print() {
      this.printing = true
      let doc = new jsPDF('p', 'pt', 'a4')
      async function addImage(canvas, doc) {
        const base64Img = canvas.toDataURL('image/jpg')
        // document.body.appendChild(canvas)
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        const imgWidth = 595.28
        const imgHeight = (592.28 / contentWidth) * contentHeight
        await doc.addImage(base64Img, 'JPEG', 0, 0, imgWidth, imgHeight)
      }
      await html2canvas(document.querySelector('#aaa')).then(canvas => {
        console.log(canvas)
        addImage(canvas, doc).then(() => {
          doc.addPage()
        })
      })
      await html2canvas(document.querySelector('#bbb')).then(canvas => {
        console.log(canvas)
        addImage(canvas, doc)
      })
      // await doc.autoPrint()
      // await doc.save('autoprint.pdf')
      const PDFbase64 = doc.output('datauristring')
      let arr = PDFbase64.split('base64,')
      let pdf = arr[arr.length - 1]
      await print({ printable: pdf, type: 'pdf', base64: true })
      this.printing = false
    },
    async downloadBook() {
      this.downloading = true
      const blob = await api.student.getAdmissionNoticePdf()
      console.log(blob)
      this.downloading = false
      let URL = window.URL || window.webkitURL
      let objectUrl = URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = objectUrl // 文件流生成的url
      a.download = '入学通知书' // 文件名
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    isShow(){
      let organizationId = this.proveInfo.organizationId;
      if(organizationId=='300000'){
          if(this.proveInfo.proveState=='3' ||this.proveInfo.proveState=='2' ){
            return this.bookInfo.unitName;
          }
          return false;
      }else {
        return this.bookInfo.unitName;
      }
    }
  },
  filters: {
    getProveState(val) {
      switch (String(val)) {
        case '1':
          return '待注册'
        case '2':
          return '已送审'
        case '3':
          return '在籍'
        case '4':
          return '休学'
        case '5':
          return '退学'
        case '6':
          return '毕业'
        case '7':
          return '转学'
        case '8':
          return '暂不注册'
        case '9':
          return '暂不送审'
        default:
          return '暂无状态'
      }
    },
    getDataState(val) {
      switch (String(val)) {
        case '1':
          return '待提交'
        case '2':
          return '提交中'
        case '3':
          return '待审核'
        case '4':
          return '审核通过'
        case '5':
          return '审核不通过'
        default:
          return '暂无状态'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.student-my-school-role {
  .role-tags {
    .tag-wrap {
      width: 19%;
      min-width: 80px;
      max-width: 80px;
      border-radius: 3px;
      overflow: hidden;
      .tag {
        padding: 20px 0;
        color: #fff;
        > p {
          margin: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.student-my-school-role {
  .notice-img-wrap {
    max-width: 540px;
    margin: 10px auto;
  }
  .title-line-wrap {
    .line {
      flex: 1;
      padding-left: 10px;
      height: 1px;
      border-bottom: 1px solid #d7dcdc;
      margin-left: 15px;
    }
  }
  // .ant-form-item {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   margin-bottom: 10px;
  //   .ant-form-item-label {
  //     min-width: 100px;
  //     max-width: 140px;
  //     >label {
  //       color: #646867;
  //     }
  //   }
  //   .ant-form-item-control-wrapper {
  //     flex: 1;
  //   }
  // }
}
</style>
