<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 19:37:56
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:19:54
 * @FilePath: \crm-education\src\views\student\my_school_role\c\BaseInfo.vue
-->
<template>
  <div class="base-info">
    <div class="title-line-wrap d-flex align-center py-10">
      <p class="fs-16 fw-bold">基本信息</p>
      <span class="line"></span>
    </div>
    <div class="content px-30">
      <a-form class="cus-form" :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="姓名">{{ proveInfo.realName }}</a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="民族">{{ proveInfo.nationName }}</a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="性别">{{ proveInfo.sex == '1' ? '男' : '女' }}</a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="出生日期">{{ proveInfo.birthTime }}</a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="证件类型">{{ proveInfo.idType | getIdType }}</a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="证件号码">{{ proveInfo.idNo }}</a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="婚姻状况">
              <a-select
                v-decorator="[
                  'marriageState',
                  {
                    initialValue: proveInfo.marriageState,
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      }
                    ]
                  }
                ]"
                placeholder="请选择婚姻状况"
              >
                <a-select-option :value="item.code" v-for="(item, index) in marriageType" :key="index">{{
                  item.desc
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="政治面貌">
              <a-select
                v-decorator="[
                  'politicsFace',
                  {
                    initialValue: proveInfo.politicsFace,
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      }
                    ]
                  }
                ]"
                placeholder="请选择政治面貌"
              >
                <a-select-option :value="item.code" v-for="(item, index) in identityType" :key="index" >{{
                  item.desc
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="籍贯">{{ proveInfo.nativePlace }}</a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="户口性质">{{
                proveInfo.registryNature | getRegistryNature
              }}
              <!--<a-select
                v-decorator="[
                  'registryNature',
                  {
                    initialValue: proveInfo.registryNature,
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      }
                    ]
                  }
                ]"
                placeholder="请选择政治面貌"
              >
                <a-select-option :value="item.code" v-for="(item, index) in residenceType" :key="index">{{
                  item.desc
                }}</a-select-option>
              </a-select>-->
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="户籍所在地">{{ proveInfo.registryAddress }}
              <!--<a-input
                v-decorator="[
                  'registryAddress',
                  {
                    initialValue: proveInfo.registryAddress,
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      }
                    ]
                  }
                ]"
                placeholder="请输入"
              ></a-input>-->
            </a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="职业分类">
              <a-select
                v-decorator="[
                  'jobState',
                  {
                    initialValue: proveInfo.jobState,
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      }
                    ]
                  }
                ]"
                placeholder="请选择在职状况"
              >
                <a-select-option :value="item.code" v-for="(item, index) in professionType" :key="index">{{
                  item.desc
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="手机号码">
              <a-input
                v-decorator="[
                  'phoneNumber',
                  {
                    initialValue: proveInfo.phoneNumber,
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      },
                      { validator: validatePhone }
                    ]
                  }
                ]"
                placeholder="请输入"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="固定号码">
              <a-input
                v-decorator="[
                  'telPhone',
                  {
                    initialValue: proveInfo.telPhone,
                    rules: []
                  }
                ]"
                placeholder="请输入"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="E-mail">
              <a-input
                v-decorator="[
                  'email',
                  {
                    initialValue: proveInfo.email,
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      },
                      { validator: validateEmail }
                    ]
                  }
                ]"
                placeholder="请输入"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="邮编">
              <a-input
                v-decorator="[
                  'zipCode',
                  {
                    initialValue: proveInfo.zipCode,
                    rules: []
                  }
                ]"
                placeholder="请输入"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="50">
          <a-col :md="{ span: 24 }" :xs="{ span: 24 }">
            <a-form-item label="通讯地址" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
              <a-input
                v-decorator="[
                  'postalAddress',
                  {
                    initialValue: proveInfo.postalAddress,
                    rules: [
                      {
                        required: true,
                        message: '必填'
                      }
                    ]
                  }
                ]"
                placeholder="请输入"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="text-center">
          <a-form-item>
            <a-button
              type="primary"
              :disabled="proveInfo.data === '1' || proveInfo.data === '2'"
              html-type="submit"
              class="cus-button"
              @click="onSubmit"
              >保存修改</a-button
            >
          </a-form-item>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { marriageType, identityType, residenceType, professionType } from '@/config/dict'
import { isPhone, isEncryptPhone, isEmail } from '@/utils/validate'
// import api from '@/api'
export default {
  name: 'my_school_role_baseinfo',
  props: {
    proveInfo: {
      type: Object
    }
  },
  data() {
    return {
      marriageType,
      identityType,
      residenceType,
      professionType,
      form: this.$form.createForm(this, { name: 'baseinfo' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      }
    }
  },
  methods: {
    onSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const data = {
            ...values,
            studProveId: this.proveInfo.studProveId,
            updateUser: localStorage.getItem('userId', '')
          }
          this.$emit('on-submit', data)
        }
      })
    },
    validatePhone(rule, value, callback) {
      const form = this.form
      const phoneVal = form.getFieldValue('phoneNumber')
      const noEncrypt = value && !isPhone(phoneVal)
      const encrypt = value && !isEncryptPhone(phoneVal)
      if (noEncrypt) {
        if (encrypt) {
          callback('手机号格式不正确')
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    validateEmail(rule, value, callback) {
      const form = this.form
      if (value && !isEmail(form.getFieldValue('email'))) {
        callback('邮箱格式不正确')
      } else {
        callback()
      }
    }
  },
  filters: {
    getIdType(val) {
      switch (String(val)) {
        case '1':
          return '身份证'
        case '2':
          return '军人证'
        case '3':
          return '护照'
        case '4':
          return '港澳居民居住证'
        case '5':
            return '台湾居民居住证'
        default:
          return '身份证'
      }
    },
      getRegistryNature(val) {
          switch (String(val)) {
              case '1':
                  return '城镇户口'
              case '2':
                  return '农村户口'
              case '3':
                  return '其他'
              default:
                  return ''
          }
      }
  }
}
</script>

<style lang="scss" scoped>
// .base-info {
// }
</style>
