<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 20:57:44
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 13:44:23
 * @FilePath: \crm-education\src\views\student\my_school_role\c\EducationChangeDialog.vue
-->
<template>
  <a-modal
    width="420px"
    class="cus-modal education-change-dialog"
    v-model="visible"
    :footer="false"
  >
    <template slot="title">
      <p class="text-center">申请学籍异动</p>
    </template>
    <div class="info">
      <a-form class="cus-form" :form="form">
        <a-form-item label="申请学籍异动类型">
          <a-select
            v-decorator="[
              'field1',
              {
                rules: [
                  {
                    required: true,
                    message: '必填'
                  }
                ]
              }
            ]"
            placeholder="请选择异动类型"
          >
            <a-select-option value="1">
              未婚
            </a-select-option>
            <a-select-option value="2">
              已婚
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="申请原因">
          <a-textarea
            v-decorator="[
              'field1',
              {
                rules: [
                  {
                    required: true,
                    message: '必填'
                  }
                ]
              }
            ]"
            placeholder="填写申请原因"
            :auto-size="{ minRows: 5, maxRows: 10 }"
          >
          </a-textarea>
        </a-form-item>
        <a-form-item label="申请日期">
          <a-date-picker
            class="cus-date-picker"
            v-decorator="[
              'field1',
              {
                rules: [
                  {
                    required: true,
                    message: '必填'
                  }
                ]
              }
            ]"
            show-time
            format="YYYY-MM-DD"
            placeholder="请选择申请日期"
          >
          </a-date-picker>
        </a-form-item>
        <div class="text-center">
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              class="cus-button"
              @click="onSubmit"
              >确认申请</a-button
            >
          </a-form-item>
          <span class="required pl-5 color-red">学籍异动</span>
        </div>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'education_change_dialog',
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this, { name: 'education_change' }),
      formData: {}
    }
  },
  methods: {
    open(options = {}) {
      this.formData = options.formData ? { ...options.formData } : {}
      this.visible = true
    },
    onSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cus-form {
  ::v-deep .ant-form-item {
    .ant-form-item-label {
      min-width: 130px;
    }
  }
}
</style>
