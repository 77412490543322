<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 20:30:20
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 20:33:29
 * @FilePath: \crm-education\src\views\student\my_school_role\c\SignOnlineInfo.vue
-->
<template>
  <div class="sign-online-info">
    <div class="title-line-wrap d-flex align-center py-10">
      <p class="fs-16 fw-bold">电子签名</p>
      <span class="line"></span>
    </div>
    <div class="content px-30">
      <div class="d-flex justify-around align-center flex-wrap">
        <div class="sign-item text-center">
          <div class="sign-wrap">
            <img :src="proveInfo.transactorSignimg" alt="经办人签名" />
          </div>
          <p class="py-10">经办人签名</p>
        </div>
        <div class="sign-item text-center">
          <div class="sign-wrap">
            <img :src="proveInfo.studSignimg" alt="学生签名" />
          </div>
          <p class="py-10">学生签名</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sign_online_info',
  props: {
    proveInfo: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-online-info {
  .sign-item {
    .sign-wrap {
      width: 200px;
      height: 200px;
      border: 1px solid #d7dcdc;
    }
  }
}
</style>
