<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 20:16:58
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:20:27
 * @FilePath: \crm-education\src\views\student\my_school_role\c\SubjectInfo.vue
-->
<template>
  <div class="subject-info">
    <div class="title-line-wrap d-flex align-center py-10">
      <p class="fs-16 fw-bold">报读信息</p>
      <span class="line"></span>
    </div>
    <div class="content px-30">
      <a-form class="cus-form">
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="专业层次">{{
              proveInfo.majorLevelInfo.majorLevelText
            }}</a-form-item>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="专业名称">{{
              proveInfo.majorName
            }}</a-form-item>
          </a-col>
        </a-row>
        <!-- TODO: 没找到这个字段 -->
        <a-row :gutter="50">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <a-form-item label="学习形式"
              >{{ proveInfo.majorLevelInfo.studyYear }}<span class="cus-alert required ml-10 color-red"
                >学籍有效期8年</span
              ></a-form-item
            >
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'subject_info',
  props: {
    proveInfo: {
      type: Object
    }
  },
  filters: {
    getMajorLevel(val) {
      switch (String(val)) {
        case '1':
          return '专科'
        case '2':
          return '本科'
        case '3':
            return '本科'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.subject-info {
  .cus-alert {
    line-height: 1.5;
  }
}
</style>
