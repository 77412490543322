<template>
  <div class="enroll-book">
    <div class="enroll-book-head">
      <img :src="require(`../assets/img/bg-book-head${page}.png`)" />
      <div class="book-title">
        <div class="book-logo"></div>
        <div class="book-txt">{{ title }}</div>
      </div>
    </div>
    <div class="enroll-book-body">
      <slot></slot>
    </div>
    <div class="enroll-book-foot"><img :src="require(`../assets/img/bg-book-foot${page}.png`)" /></div>
  </div>
</template>

<script>
export default {
  name: 'NoticeBook',
  components: {},
  props: {
    title: { type: String, default: () => '' },
    page: { type: String, default: () => '1' }
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.enroll-book {
  min-height: 1200px;
  img {
    display: block;
    max-width: 100%;
  }
  &.book-back {
    .enroll-book-body {
      min-height: auto;
    }
  }
  .enroll-book-head {
    position: relative;
    .book-title {
      height: 106px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
    .book-logo {
      width: 106px;
      height: 106px;
      background: url(../assets/img/bg-book-logo.png) center center / 100% auto no-repeat;
    }
    .book-txt {
      font-size: 22px;
      font-weight: bold;
      color: #fff;
    }
  }

  .enroll-book-body {
    min-height: 580px;
  }
}
</style>
